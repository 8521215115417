/**
 * The ActStatus from account info
 */

export const PromotionType = {
	Close: 0,
	Common: 1, // 通用版型
	Special: 2, // 特殊活動版 現在是Euro cup view
}

export const AccountActStatus = {
	NotDeposit: 1,
	Deposited: 2
}

export const WebSkinType = {
	Galaxy: 7,
	Mars: 8
};

export const KafkaNote = {
	GalaxyTransfer: 'Galaxy_Transfer',
	GalaxyV2: 'Galaxy_Behavior_V2',
	MarsV1: 'Mars_Behavior',
	EuroCup: '2024_EuroCup_Promotion',
	Reward: 'VIP',
	EsportsWeb: 'E-Sports_Web_Behavior',
	PromotionBet: 'PromotionBet',
};

export enum KafkaGalaxyTypeEnums {
	AppBarAndSetting = 11
}
export enum KafkaMarsTypeEnums {
	QuickSettingAndMe = 7,
	PinGoal = 15
}
export const KafkaActionSettings = {
	GalaxyV2: {
		[KafkaGalaxyTypeEnums.AppBarAndSetting]: {
			IndependentBetting: 19,
			MultipleBetting: 20,
		},
	},
	MarsV1: {
		[KafkaMarsTypeEnums.QuickSettingAndMe]: {
			BettingMode: 32,
			IndependentBetting: 33,
			MultipleBetting: 34,
		},
		[KafkaMarsTypeEnums.PinGoal]: {
			PinGoal: 1,
			PinGoalSoccer: 2,
			PinGoalBasketball: 3,
			PinGoalEsport: 4,	
		}
	}
}

export const StreamingKafkaNote = {
	MiniStreaming: '1',
	SingleMatch: '2',
	Popup: '3',
	Lobby: '4',
	OddsPage: '5',
};

export const ApiErrorCode = {
	UM: 'WAE-1001',
	IpBlock: 'WAE-1002',
	LoginCheckinKickout: '100',
	Multilogin: '106',
	SysIdle: '102',
	SysLogout: '103',
	FetchOddsDataError: 'WAE-1017',
	GetBalanceError: 'SP-1030',
	SysError: '500'
};

export enum WebOddsType {
	DEC = 1,
	HK,
	INDO,
	MY,
	US
}

export enum IncDecType {
	Increment = 'Increment',
	Decrement = 'Decrement'
};

export const LoginCheckinErrorTitle = {
	'0': 'SysLogout',
	'100': 'SysLogout',
	'102': 'SysIdle',
	'103': 'SysLogout',
	'106': 'SysMultilogin',
	'500': 'Opps',
	'default': 'Opps'
};

export const LoginCheckinErrorMessage = {
	'0': 'SysLogoutmsg',
	'100': 'SysLogoutmsg',
	'102': 'SysIdleMsg',
	'103': 'SysLogoutmsg',
	'106': 'SysMultiloginMsg',
	'500': 'SystemError',
	'default': 'Opps'
};

export const locales = [
	'en-US', 'zh-TW', 'zh-CN', 'zh-Hans', 'id-ID', 'ja-JP', 'ko-KR', 'th-TH', 'vi-VN', 'pt-PT', 'ru-RU', 'my-MM',
	'pt-BR', 'es-ES', 'de-DE', 'bn-BD', 'hi-IN', 'it-IT', 'ms-MY', 'km-KH', 'lo-LA', 'ar-EG', 'ta-IN', 'fr-FR'
];