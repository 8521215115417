import { fetchComposer } from '@nf/utils-common/compose-fetch';
import { useSiteStore } from '@/store/site';
import { getAuthToken } from '@/store/auth';
import { ApiQueryPromise, ViewEnum } from '@/types';
import { getFormData } from '@nf/utils-common/form';
import { getResponseJwtHeader } from '@/utils/web';
import { cloudUrlParser } from '@/utils/aws-domain-util';
import { KafkaNote } from '@nf/utils-common/constants';
import { useAppearanceStore } from '@/store/appearance';

export interface setKafkaResponse {
	success: boolean;
}

export interface setKafkaWithStringBody {
	type: number;
	action: string;
	note?: string;
	kType?: string;
	topic?: number;
}

export interface setNormalKafkaBody {
	type: number;
	action: number;
	note?: string;
	kType?: string;
}

export interface setStreamingKafkaBody {
	type: number;
	action: number;
	note: string;
	kType?: string;
	matchId: number;
	streamer_type: number;
}

enum KafkaType {
	Normal = 'Normal',
	Streaming = 'Streaming'
};

export const setKafkaWithStringAction = async (kafkaData: setKafkaWithStringBody): Promise<ApiQueryPromise<setKafkaResponse>> => {
	const apiDomain = useSiteStore.getState().apiDomain.ctcdDomain;
	const apiUrl = new URL('/Default/SetKafkaWithString', apiDomain);

	kafkaData.kType = KafkaType.Normal;
	kafkaData.note = kafkaData.note ?? KafkaNote.GalaxyV2;

	return fetchSetKafka(apiUrl.href, kafkaData);
}

export const setKafkaNormal = async (kafkaData: setNormalKafkaBody, token?: string): Promise<ApiQueryPromise<setKafkaResponse>> => {
	const apiDomain = useSiteStore.getState().apiDomain.ctcdDomain;
	const apiUrl = new URL('/Default/SetKafka', apiDomain);

	kafkaData.kType = KafkaType.Normal;
	kafkaData.note = kafkaData.note ?? KafkaNote.GalaxyV2;

	// 在 mars 先擋掉可能汙染 galaxy 數據的埋點
	const view = useAppearanceStore.getState().view;
	if (view === ViewEnum.mars && kafkaData.note === KafkaNote.GalaxyV2)
		return Promise.resolve({} as ApiQueryPromise<setKafkaResponse>);

	return fetchSetKafka(apiUrl.href, kafkaData, token);
}

export const setKafkaStreaming = async (kafkaData: setStreamingKafkaBody): Promise<ApiQueryPromise<setKafkaResponse>> => {
	const apiDomain = useSiteStore.getState().apiDomain.ctcdDomain;
	const apiUrl = new URL('/Default/SetKafka', apiDomain);
	kafkaData.kType = KafkaType.Streaming;
	return fetchSetKafka(apiUrl.href, kafkaData);
}

const fetchSetKafka = async (apiUrl: string, kafkaData: setKafkaWithStringBody | setNormalKafkaBody | setStreamingKafkaBody, token?: string): Promise<ApiQueryPromise<setKafkaResponse>> => {
	const allowSetKafka = checkAllowSetKafka(kafkaData.kType, kafkaData.note);
	if (!allowSetKafka) {
		return Promise.resolve({} as ApiQueryPromise<setKafkaResponse>);
	}

	const authToken = getAuthToken();
	const response = await fetchComposer.postWithBearerBase(cloudUrlParser(apiUrl), { body: getFormData(kafkaData) })(token ?? authToken);
	if (!response.ok) {
		throw new Error(`${response.status}: ${response.statusText}`);
	}
	return {
		data: await response.json(),
		jwtToken: getResponseJwtHeader(response)
	};
}

const checkAllowSetKafka = (kafkaType?: string, kafkaNote?: string) => {
	const enableKafa = useSiteStore.getState().enableKafa.split(';');
	let allowSetKafka = false;

	if (enableKafa.includes('-1')) { // close all
		// do nothing
	} else if (enableKafa.includes('0')) { // open all
		allowSetKafka = true;
	} else {
		if (enableKafa.includes('1') && kafkaType === KafkaType.Streaming) { // allow streaming
			allowSetKafka = true;
		}
		if (enableKafa.includes('2') && kafkaType === KafkaType.Normal && kafkaNote === KafkaNote.EuroCup) { // allow euro cup promotion
			allowSetKafka = true;
		}
	}

	return allowSetKafka;
}