import { useRouter } from '@/navigation';
import { useQueryClient } from '@tanstack/react-query';
import { useAuthData, useAuthActions } from '@/store/auth';
import { ApiErrorCode } from '@nf/utils-common/constants';
import { matchEquals } from '@nf/utils-common/fpw/monadic';
import { deleteUserSession } from '@nf/services/actions/auth/session';

export const useAccountError = () => {
	const router = useRouter();
	const queryClient = useQueryClient();
	const { userName, siteName, uuid } = useAuthData() ?? {};
	const { signOut } = useAuthActions();

	const signOutAndRedirect = async (path: string) => {
		if (userName && siteName && uuid) {
			await deleteUserSession({
				name: userName,
				site: siteName,
				uuid
			});
			queryClient.clear();
			signOut();
		}
		router.push(path);
	};
	
	const handleAccountError = (errorCode?: string, errorMsg?: string, logId?: string) => {
		if (!errorCode) return;
		
		if (errorCode.startsWith('SP-1067')) {
			const webErrorCode =  errorCode.split('_')[0];
			const singleWalletErrorCode = errorCode.split('_')[1];
			signOutAndRedirect(`/app-error?errorcode=${webErrorCode}&singleWalletErrorCode=${singleWalletErrorCode}&logId=${logId}`);
			return;
		}
		
		matchEquals(errorCode)
			.on(ApiErrorCode.GetBalanceError, () => {
				signOutAndRedirect(`/app-error?errorcode=${ApiErrorCode.GetBalanceError}&logId=${logId}`);
			})
			.on(ApiErrorCode.LoginCheckinKickout, () => {
				signOutAndRedirect(`/app-error?errorcode=100&logId=${logId}`);
			})
			.on(ApiErrorCode.UM, () => {
				signOutAndRedirect('/um');
			})
			.on(ApiErrorCode.Multilogin, () => {
				signOutAndRedirect(`/app-error?errorcode=106&logId=${logId}`);
			})
			.on(ApiErrorCode.SysIdle, () => {
				signOutAndRedirect(`/app-error?errorcode=102&logId=${logId}`);
			})
			.on(ApiErrorCode.SysLogout, () => {
				signOutAndRedirect(`/app-error?errorcode=103&logId=${logId}`);
			})
			.on(ApiErrorCode.SysError, () => {
				signOutAndRedirect(`/app-error?errorcode=500&logId=${logId}`);
			})
			.on(ApiErrorCode.IpBlock, () => {
				signOutAndRedirect('/limit');
			})
			.otherwise(() => console.error(`${errorCode}: ${errorMsg}`));
	};

	return { handleAccountError };
};