import { SportSettings, SportSetting } from '@/types/settings';
import { BetTypeSettings, BetTypeSetting, LayoutType } from '@/types/bet-type-settings';
import { siteSettings } from '@/settings/site-settings';
import { 
	galaxyForbiddenSports, 
	marsForbiddenSports, 
	sportSettings,
	numberGameIds,
	virtualSportIds,
	dummySettings,
} from '@/settings/sports-settings';
import { betTypeSettings } from '@/settings/bet-type-settings';
import { SportCategorySettings, CategorySettings, CategoryNameCriteria, CategoryBetTypeSettingCriteria, Category } from '@/types/category-setting';
import { sportCategorySettings, categorySettings  } from '@/settings/category-settings';
import { Market } from '@nf/types/odds';
import { ViewEnum } from '@/types';
import { galaxyMarketMenuPriorityMapping, marsMarketMenuPriorityMapping, marsParlayMarketMenuPriorityMapping } from '@/settings/menu-type-settings';
import { viewLayoutSettings } from '@/settings/layout-settings/view-layout-settings';
import { viewChoiceSettings } from '@/settings/choice-settings/view-choice-settings';
import { newbieSportSettings } from '@/settings/newbie-sport-settings';
import { betTypeTutorialSettings } from '@/settings/bet-type-tutorial-settings';
import { specialSportSettings } from '@/settings/special-sport-setting';

export const getSiteProtectedPaths = () => siteSettings.protectedPaths;

export const getSiteIgnoredCheckPaths = () => siteSettings.ignoredCheckPaths;

export const getSportSetting = (sport: number): SportSetting => {
	return sportSettings[sport as keyof SportSettings] ?? sportSettings[0];
};

export const getNewbieSportSettings = (sport: number): SportSetting => newbieSportSettings[sport] ?? newbieSportSettings[0];

export const getBetTypeSetting = (betType: number): BetTypeSetting => {
	return betTypeSettings[betType as keyof BetTypeSettings];
};

export const getCustomizeBetChoice = (betType: number, view: ViewEnum = ViewEnum.galaxy) => {
	return viewChoiceSettings[view]?.[betType];
}

export const getSportCategorySetting = (sportType: number): Array<Category> => {
	return sportCategorySettings[sportType as keyof SportCategorySettings] ?? sportCategorySettings[0];
}

export const getCategoryName = ({ sportType, category }: CategoryNameCriteria): string => {
	const categorySetting = categorySettings[category as keyof CategorySettings];
	return categorySetting.title[sportType] ?? categorySetting.title[0];
}

export const isSpecialLeague = (category: Category): boolean => {
	const subMatchCategories: Category[] = ['Main', 'Half', 'Full', 'ExtraTime', 'Penalty', 'Corners', 'Intervals', 'Specials', 'Players', 'Innings', 'Hits', 'Match'];
	
	return subMatchCategories.includes(category);
}

export const getSpecialLeagueCategoryName = (sportId: number, cmt: number, betTypes: Market[] = []): Category => {
	let categoryName: Category = [1, 6, 9, 50].includes(sportId) ? 'Main' : 'Full';

	switch (cmt) {
		case 201:
			categoryName = 'ExtraTime';
			break;
		case 202:
		case 203:
			categoryName = 'Penalty';
			break;
		case 204:
		case 205:
		case 209:
			categoryName = 'Corners';
			break;
		case 206:
			categoryName = 'Intervals';
			break;
		case 207:
			categoryName = 'Specials';
			break;
		case 208:
			categoryName = 'Players';
			break;
		case 210:
			categoryName = 'Full';
			break;
		case 211:
			categoryName = 'Half';
			break;
	}

	if (sportId == 43) {
		const cat = betTypes.filter(betType => [1, 3, 7, 8, 20].includes(betType.BetTypeId))[0]?.Cat;
		if (cat > 10) {
			return `Map${cat - 10}` as keyof CategorySettings;
		}
	}

	return categoryName;
}

export const getCategoryBetTypeSettings = ({
	sportType, 
	category,
	isLive,
	gameType
}: CategoryBetTypeSettingCriteria): Array<number> | undefined => {

	const categorySetting = categorySettings[category as keyof CategorySettings] ?? categorySettings['Main'];

	const key = getCategoryBetTypeSettingKey({ sportType, category, isLive, gameType });

	return categorySetting.betTypeSettings[key] ?? categorySetting.betTypeSettings[0];
}

export const getForbiddenSport = (view: ViewEnum) => {
	return view === ViewEnum.galaxy ? galaxyForbiddenSports : marsForbiddenSports;
}

export const getMarketPriority = (view: ViewEnum, isParlay?: boolean) => {
	if (view === ViewEnum.galaxy) {
		return galaxyMarketMenuPriorityMapping;
	}
	
	return isParlay ? marsParlayMarketMenuPriorityMapping : marsMarketMenuPriorityMapping;
}

export const isVirtualSports = (sportId: number) => {
	return (
		(sportId >= 180 && sportId <= 186) ||
		(sportId >= 190 && sportId <= 197) ||
		sportId === 178 ||
		sportId === 179 ||
		sportId === 188
	);
}

export const isDummySportCategory = (sportId: number) => dummySettings.findIndex(setting => setting.categoryId === sportId) !== -1

export const isNumberGames = (sportId: number) => {
	return [161, 164].includes(sportId);
}
export const isEndingGames = (sportId: number, betTypeId: number) => {
	return sportId === 2 && betTypeId >= 647 && betTypeId <= 650;
}

const getCategoryBetTypeSettingKey = ({
	sportType,
	isLive,
	gameType
}: CategoryBetTypeSettingCriteria) : string => {
	let key = sportType.toString();

	if (sportType == 50 && gameType != 'none') {
		key = gameType == 'fixed' ? `${key}-FX` : `${key}-FC`
	}

	if (sportType == 6 || sportType == 8 ||
		(sportType == 50 && gameType == 'fixed')) {
		key = isLive ? `${key}_l` : `${key}_d`
	}

	return key;
}

export const getCustomizeLayoutType = (betType: number, view: ViewEnum = ViewEnum.galaxy, sportId: number = 0): LayoutType | undefined => {
	return viewLayoutSettings[view]?.[betType]?.[sportId] 
		?? viewLayoutSettings[view]?.[betType]?.[0];
}

export const getBetTypeTutorialSetting = (betType: number) => betTypeTutorialSettings[betType];

export const getSpecialSportSetting = (sport: number) => specialSportSettings[sport]

export const getNonNormalizedSports = () => new Set([...numberGameIds, ...virtualSportIds]);